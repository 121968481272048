import * as React from 'react';

export interface ISpinnerProps {}

export const Spinner: React.FunctionComponent<ISpinnerProps> = (props: ISpinnerProps) => {
  return (
    <div className="fixed flex flex-col justify-center items-center h-full w-full">
      <div className="animate-pulse">
        <svg className="h-32 w-32 text-skyblue-700" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="popcorn" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M443.62 138.88a42.72 42.72 0 0 0-33.09-20.79 37.89 37.89 0 0 0-.33-37.43c-9.11-16-28-23.69-45.55-20.17-.33-16.64-11.47-32-29-37.43a43.36 43.36 0 0 0-39.14 6.4 4.25 4.25 0 0 0-.68-1.92C288.73 6.42 264.77-4.78 242.5 2a41.66 41.66 0 0 0-27.32 27.19 43.4 43.4 0 0 0-38.82-6.08c-17.54 5.44-28.66 20.79-29 37.43-17.56-3.54-36.46 4.12-45.57 20.12a37.18 37.18 0 0 0-.33 37.43c-13.46 1.28-26.32 8.64-33.06 20.79-3.92 6.74-4.77 14-4.27 21.12h383.73c.52-7.12-.33-14.37-4.24-21.12zM366.4 192l-28 256h-45.01L315 192H197.05l21.56 256h-45.05l-28-256H64l43.91 292.75A32 32 0 0 0 139.56 512h232.88a32 32 0 0 0 31.65-27.25L448 192z"></path></svg>
      </div>

      <p className="mt-4 text-opacity-75 text-3xl antialiased">Take a seat, loading the event.</p>
    </div>
  );
};