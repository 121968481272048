import * as React from 'react';
import { useRecoilState } from 'recoil';
import { ConfigState } from '../states/ConfigState';

export interface ISponsorsOverviewProps {}

export const SponsorsOverview: React.FunctionComponent<ISponsorsOverviewProps> = (props: ISponsorsOverviewProps) => {
  const [ config ] = useRecoilState(ConfigState);
  
  if (!config || !config.sponsors || config.sponsors.length <= 0) {
    return null;
  }

  let sortedSponsors = [...config.sponsors].sort((a, b) => {
    if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
    if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
    return 0;
  });

  return (
    <section className="container mx-auto leading-none mt-8 mb-16" role="list">
      <h2 className="text-display text-3xl text-center my-8">Our yearly sponsors</h2>
      <div className="grid grid-cols-3 gap-4">
      {
        sortedSponsors.map(sponsor => (
          <div key={`sponsor-${sponsor.title.replace(/ /g, '_')}`} className="flex justify-center items-center">
            <a key={sponsor.title.replace(/ /g, '_')} className="sponsor" href={sponsor.url} target="_blank" title={sponsor.title} rel="noopener noreferrer">
              <img className="w-1/3 mx-auto" src={sponsor.logo} alt={sponsor.title} />
            </a>
          </div>
        ))
      }
      </div>
    </section>
  );
};