import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { CacheService } from '@valo/cache';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import * as history from 'history';
import './index.tailwind.css';

const browserHistory = history.createBrowserHistory();
export const HistoryContext = React.createContext<History>(browserHistory as any);
export const CacheContext = React.createContext<CacheService>(new CacheService("EventCache"));

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
