import * as React from 'react';
import { Session as SessionInfo } from '../models';
import { Speakers } from './Speakers';

export interface ISessionProps extends SessionInfo {
  className?: string;
}

export const Session: React.FunctionComponent<ISessionProps> = (props: ISessionProps) => {
  const { title, description, speakers, className } = props;

  return (
    <div className={`grid__session border-l-2 border-gray-200 flex flex-col justify-between px-4 py-4 md:py-8 ${className ? className : ""}`} >
          
      <h3 className="grid__session__title font-medium title-font mb-4">
        <span className="text-left">{title}</span>
      </h3>
      
      <div>
        <p className=" mb-4">{description}</p>
        
        <Speakers speakers={speakers} />
      </div>
    </div>
  );
};