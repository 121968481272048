import React from 'react';
import { useRecoilState } from 'recoil';
import { Spinner } from './components';
import { useConfig } from './hooks/useConfig';
import { Main } from './Main';
import { ConfigState } from './states/ConfigState';

export default function App() {
  const [ config ] = useRecoilState(ConfigState);
  useConfig();
  
  if (config) {
    return <Main />;
  }

  return <Spinner />;
}
