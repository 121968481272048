import * as React from 'react';
import { Session } from '.';
import { Session as SessionInfo } from '../models';
import { TimeSlot } from './TimeSlot';

export interface ISessionOverviewProps {
  sessions: SessionInfo[];
}

export const SessionOverview: React.FunctionComponent<ISessionOverviewProps> = (props: ISessionOverviewProps) => {
  const { sessions } = props;

  const getCells = (session: SessionInfo): JSX.Element[] => {
    const cells: JSX.Element[] = [];

    cells.push(<Session key={session.title.replace(/ /g, "")} {...session} />);

    return cells;
  }

  let gridMarkup: JSX.Element[] = [];

  // Create all the sessions
  const timeslots: { startsAt: string, endDate: string, cells: JSX.Element[] }[] = [];
  for (const session of sessions) {
    if (session) {
      const timeslot = timeslots.find(t => t.startsAt === session.startsAt);
      if (timeslot) {
        timeslot.cells = [...timeslot.cells, ...getCells(session)]
      } else {
        timeslots.push({
          startsAt: session.startsAt,
          endDate: session.endsAt,
          cells: getCells(session)
        });
      }
    }
  }

  // Create all the timeslots + related sessions
  for (const timeslot of timeslots) {
    gridMarkup = [...gridMarkup, <TimeSlot key={`time_${timeslot.startsAt}`} startsAt={timeslot.startsAt} endsAt={timeslot.endDate} />, ...timeslot.cells];
  }

  return (
    <>
      {
        gridMarkup && (
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 leading-normal">
            {gridMarkup}
          </div>
        )
      }
    </>
  );
};