import * as React from 'react';
import { Speaker } from '../models';

export interface ISpeakerDetailsProps {
  speaker: Speaker;
}

export const SpeakerDetails: React.FunctionComponent<ISpeakerDetailsProps> = (props: ISpeakerDetailsProps) => {
  const { speaker } = props;
  
  return (
    <div className="speakers__overview__details rounded-md w-full md:w-1/2 p-8 text-center">
      <img className="w-1/3 mx-auto mb-4 rounded-full border-4 border-white" src={speaker.img} alt={speaker.name} />
      <h3 className="text-xl font-heading  mb-4">{speaker.name}</h3>
      <p className="text-gray-700 mb-4">{speaker.tagLine}</p>

      {
        speaker.twitter && (
          <p className="text-base mb-4">
            <a href={`https://twitter.com/${speaker.twitter}`} title={`Twitter: ${speaker.name}`} target="_blank" rel="noopener noreferrer">
              <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>

              <span className="ml-2">Twitter</span>
            </a>
          </p>
        )
      }
      {
        speaker.website && (
          <p className="text-base">
            <a href={speaker.website} title={`Website: ${speaker.name}`} target="_blank" rel="noopener noreferrer">
              <svg className="icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path></svg>

              <span className="ml-2">Website</span>
            </a>
          </p>
        )
      }
    </div>
  );
};