import * as React from 'react';
import { Channel as ChannelInfo } from '../models';

export interface IChannelProps {
  info: ChannelInfo;
}

export const Channel: React.FunctionComponent<IChannelProps> = (props: IChannelProps) => {
  const { info } = props;

  return (
    <div className="w-full">
      <iframe title={info.title} className="channel__session__iframe w-full" height="720" src={info.sessionUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={!!info.allowFullScreen}></iframe>
    </div>
  );
};