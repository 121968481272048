import * as React from 'react';
import { Speaker as SpeakerInfo } from '../models';

export interface ISpeakerProps {
  idx: number;
  speaker: SpeakerInfo;
}

export const Speaker: React.FunctionComponent<ISpeakerProps> = (props: ISpeakerProps) => {
  const { idx, speaker } = props;

  if (speaker.twitter || speaker.website) {
    return (
      <>
        { idx > 0 && <span className="mr-2">,</span>}
        <a href={`${speaker.twitter ? `https://twitter.com/${speaker.twitter}`: speaker.website}`} target="_blank" title={`${speaker.name}`} rel="noopener noreferrer">{speaker.name}</a>
      </>
    );
  }

  return <span>{idx > 0 ? ', ' : ''}{speaker.name}</span>;
};